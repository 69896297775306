@font-face {
  font-family: 'Zawgyi-One';
  src: url('../fonts/Zawgyi-One.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Zawgyi-One.eot?#iefix') format('embedded-opentype') , /* IE6-IE8 */
       url('../fonts/Zawgyi-Ones.ttf')  format('truetype'); /* Safari , Android , iOS */
	font-display: swap;
}
/* Default 
-----------------------------------------------------*/
* {
	font-family: Lato ,'zawgyi-one' ,sans-serif ,arial;
	font-size: 14px;
	text-decoration: none !important;
	outline: none !important;
}
.i18n-my-zawgyi .node * {
	line-height: 22px;
	letter-spacing: 1.2px;
}
.page-title {
	font-family: Raleway ,'zawgyi-one';
}
b * ,
b ,
strong * ,
strong {
	font-family: Raleway , zawgyi-one;
	font-weight: 500;
}
.i18n-my-zawgyi .node strong {
	font-weight: 700;
}
img {
	vertical-align: bottom;
}
p {
	margin-bottom: 10px;
}
.messages p {
	margin: 0;
}
.messages {
	padding-left:25px !important;
	padding-right:25px !important;
}
.node h2 {
	text-transform: uppercase;
	margin-bottom: 10px;
	display: block;
	font-weight: 600;
	font-family: 'Raleway' ,zawgyi-one;
	font-size: 15px;
}
.i18n-my-zawgyi .node h2 {
	font-weight: 700;
	font-size: 14px;
}
.node p {
	margin-bottom: 0px;
}
body {
	width: 100%;
	color: #000;
	background-color: #f5f5f5;
	position: relative;
	min-width: 1105px;
}
.node ul {
	margin-left: 20px;
	margin-bottom: 10px;
}
.node ol {
	margin-left: 28px;
	margin-bottom: 10px;	
}
a:link ,
a:visited ,
a:hover ,
a {
	color: #000;
}
#page {
	max-width: 1100px;
	margin-top:0 auto;
	padding-top: 108px;
	background-color: #fff;
	box-shadow: 0 0 5px #888;
	-moz-box-shadow: 0 0 5px #888;
	-o-box-shadow: 0 0 5px #888;
	-webkit-box-shadow: 0 0 5px #888;
}
.front #page {
	padding-top: 37px;
}
h1.title {
	text-transform: uppercase;
	margin: 0;
	font-size: 25px;
	padding-bottom: 0;
	font-weight: 400;
	font-family: Raleway ,zawgyi-one;
}
h2.block-title {
	font-family: Raleway ,zawgyi-one;
	font-weight: 600;
	font-size: 16px;
	text-transform: capitalize;
	position: relative;
	z-index: 10;
	margin-bottom: 10px;
}
#content-header {
	width: 100%;
	overflow: hidden;
	clear: both;
}
.b-logo {
	width: 144px;
}
.b-logo img {
	width: 100%;
	height: auto;
}
input[type=checkbox] ,
input[type=radio] {
	display: none !important;
}
input[type=checkbox] + label ,
input[type=radio] + label {
	padding-left: 22px;
	display: inline-block;
	background-repeat: no-repeat;
	vertical-align: middle;
	cursor: pointer;
	background-position: left center;
}
input[type=checkbox] + label {
	padding-left: 22px;
	padding-right: 0;
	background-image: url("../images/checkbox0.png");
}
input[type=checkbox]:checked + label {
	background-image: url("../images/checkbox1.png");
}
.view-exposed-type-checkbox label ,
.form-type-checkbox label ,
.view-exposed-type-checkbox label ,
.form-type-radio label {
	margin-top: 0px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
input.form-submit ,
input.form-submit {
	margin:0 !important;
	padding: 5px;
	font-weight: 500;
	font-size: 12px;
	text-transform: uppercase;
	border:1px solid #333;
	background-color: #333;
	color: #fff;
}
input.form-submit:hover ,
input.form-submit:hover {
	background-color: #fff;
	color: #000;
}
textarea ,
input[type='email'] ,
input[type='text'] ,
input[type='number'] ,
input[type='password'] {
	border: 1px solid #ccc;
	padding:5px;
}
textarea:focus ,
input[type='email']:focus ,
input[type='text']:focus ,
input[type='number']:focus ,
input[type='password']:focus {
	border-color: #000;
}
.grippie {
	display: none;
}
#map-canvas #info * {
	font-weight: 500;
	font-family: Raleway;
}
#map-canvas #info {
	width: 150px;
	text-align: center;
}
#map-canvas img {
	max-width: none !important;
}
div.gm-style div:nth-child(2) img ,
div.gm-style div:nth-child(2) a img {
	display: none !important;
}
div.gm-style div:nth-child(1) img {
	display: block !important;
}
div.gm-style .gmnoprint .gm-style-cc ,
div.gm-style .gmnoprint.gm-style-cc {
	display: none !important;
}
legend {
	display: none;
}
legend ,
fieldset {
	padding:0;
	margin:0;
	border:0px none;
}
/*===================================================*/


/*	hidden css-images to download
----------------------------------------------------- */
#logo:before {
	opacity: 0;
	content: ' ';
	width: 10px;
	height: 10px;
	background-color: red;
	background-image: url('../images/checkbox0.png');
}
#logo:after {
	opacity: 0;
	content: ' ';
	width: 10px;
	height: 10px;
	background-color: red;
	background-image: url('../images/checkbox1.png');
}
/*===================================================*/


/* header 
-----------------------------------------------------*/
#header {
	position: fixed;
	width: 100%;
	z-index: 200;
	left: 0;
	top: 0;
}
.logged-in #header {
	top: 64px;
}
#header-second-wrapper ,
.region-header {
	max-width: 1100px;
	margin: 0 auto;
	width: 100%;
}
.region-header {
	overflow: hidden;
}
#header-second {
	background-color: rgba(255 ,255 ,255 ,0.8);
}
#header * ,
#header-second * {
	font-family: Raleway ,zawgyi-one;
}
.not-front #header {
	border-bottom: 1px solid #ccc;
}
	/*-header-first-*/
	#header-first {
		background-color: #000;
	}
	#header-first * {
		color: #fff;
		vertical-align: middle;
		font-weight: 600;
	}
	#block-menu-menu-first-menu ,
	#block-locale-language {
		float: right;
	}
	#block-locale-language {
		padding: 10px 0;
	}
	#block-locale-language h2.block-title {
		margin: 0;
		font-size: 11px;
		float: left;
	}
	#block-locale-language .block-inner {
		overflow: hidden;
		width: 100%;
	}
	#block-locale-language .content {
		float: right;
		display: table;
	}
	#block-locale-language .content ul img {
		display: none;
	}
	#header-first .block .content ul {
		display: block;
		overflow: hidden;
	}
	#header-first .block .content ul li {
		display: table;
		margin-left: 5px;
		padding: 0;
		margin-right: 0px;
		margin-top: 0px;
		margin-bottom: 0px;
		float: left;
	}
	#header-first .block .content ul li a {
		display: block;
		overflow: hidden;
	}
	#block-locale-language .content ul li a {
		font-size: 0;
		color: rgba(0 ,0 ,0 ,0);
		width: 26px;
		height: 17px;
		display: block;
		background-color: #222;
		overflow: hidden;
		background-repeat: no-repeat;
		background-position: center center;
	}
	#block-menu-menu-first-menu .content ul li a {
		padding: 8px 10px;
		text-align: center;
		font-size: 11px;
		margin-left: 10px;
		text-transform: uppercase;
	}
	#block-menu-menu-first-menu .content ul li a.active ,
	#block-menu-menu-first-menu .content ul li a:hover {
		background-color: #222;
	}
	#block-locale-language .content ul li.en a {
		background-image:url('../images/en.png');
	}
	#block-locale-language .content ul li.my-zawgyi a {
		background-image:url('../images/my-zawgyi.png');
	}
	#block-locale-language .content ul li a {
		opacity: 0.5;
	}
	#block-locale-language .content ul li a.active {
		opacity: 1;
	}
	.locale-untranslated {
		display: none;
	}
	/*--*/
	
	/*-header-second-*/
	#header-second-wrapper {
		position: relative;
		padding: 10px 25px;
	}
	#logo {
		display: table;
	}
	#second-header-region {
		position: absolute;
		margin-right: 8px;
		bottom: 0;
		right: 0;
	}
	#header-second .block .content ul * {
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 500;
	}
	#header-second .block .content ul {
		display: block;
	}
	#header-second .block .content ul li {
		height: 48px;
		display: table;
		padding: 0;
		margin-left: 30px;
		margin-right: 0px;
		margin-top: 0px;
		margin-bottom: 0px;
		float: left;
	}
	#header-second .block .content ul li .nolink ,
	#header-second .block .content ul li a {
		display: block;
		overflow: hidden;
		padding-bottom: 2px;
		cursor: pointer;
		border-bottom: 2px solid rgba(0 ,0 ,0 ,0);
	}
	#header-second .block .content ul li .nolink:hover ,
	#header-second .block .content ul li a:hover {
		border-color:#e21f26;
	}
	.node-type-activities #header-second .block .content ul li.activities a,
	#header-second .block .content ul li .nolink.active-trail ,
	#header-second .block .content ul li a.active-trail ,
	#header-second .block .content ul li a.active {
		border-color:#e21f26;
		font-weight: 600;
	}
	#header-second .block .content ul li.expanded:hover li .nolink ,
	#header-second .block .content ul li.expanded:hover li a {
		border-color:rgba(0 ,0 ,0 ,0);
	}
	#header-second .block .content ul li.expanded:hover li a.active ,
	#header-second .block .content ul li.expanded:hover li .nolink:hover ,
	#header-second .block .content ul li.expanded:hover li a:hover {
		border-color:#e21f26;
		background-color: #232323;
	}
	#header-second .block .content ul li.expanded {
		position: relative;
	}
	#header-second .block .content ul li.expanded ul li {
		height: auto;
		float: none;
		display: block;
		margin:0;
	}
	#header-second .block .content ul li.expanded ul a ,
	#header-second .block .content ul li.expanded ul .nolink {
		color: #fff;
		padding-top: 4px;
		padding-bottom: 1px;
		padding-left: 10px;
		padding-right: 10px;
		/*border:0px none;*/
	}
	#header-second .block .content ul li.expanded ul {
		background-color: rgba(0 ,0 ,0 ,0.7);
		position: absolute;
		display: none;
		min-width: 200px;
		padding: 5px 0;
		top: 48px;
		left: 0;
	}
	#header-second .block .content ul li.expanded:hover ul {
		display: block;
	}
	/*--*/
/*===================================================*/

/*-hightlight 
-----------------------------------------------------*/
	/*-#block-views-slideshow-block-*/
	#block-views-slideshow-block .field-content {
		overflow: hidden;
	}
	#block-views-slideshow-block .field-slideshow {
		height: 511px !important;
	}
	/*--*/

	/*-#block-block-1-*/
	#block-block-1 .content {
		overflow: hidden;
		height: 388px;
	}
	.brand-case img {
		width: 100%;
		height: auto;
	}
	.brand-logo ,
	.brand-image {
		display: table;
	}
	.brand-case {
		overflow: hidden;
		display: table;
		position: relative;
	}
	.brand-logo a {
		display: table;
		margin: 0 auto;
	}
	.brand-logo {
		text-align: center;
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		cursor: pointer;
	}
	.small-brand .brand-case {
		background-color: #333;
		float: left;
		display: block;
		overflow: hidden;
		height: 194px;
		width: 275px;
	}
	.big-brand {
		float: left;
		display: table;
	}
	.small-brand {
		display: table;
		width: 550px;
		float: right;
	}
	.image-cover {
		background-color: rgba(0 ,0 ,0 ,0.4);
		position: absolute;
		left: 0;
		top: 0;
		transition:0.2s background-color;
		-moz-transition:0.2s background-color;
		-webkit-transition:0.2s background-color;
		-o-transition:0.2s background-color;
	}
	.big-brand .image-cover {
    height: 389px;
    width: 550px;
	}
	.small-brand .image-cover {
		height: 389px;
    width: 550px;
	}
	.brand-case:hover .image-cover {
		background-color: rgba(0 ,0 ,0 ,0);
	}
	/*--*/
/*===================================================*/

/* Content
-----------------------------------------------------*/
.not-front #content-area {
	margin-bottom: 50px;
}
.not-front .region-content .field {
	padding: 0 25px;
}
.page-user .region-content ,
.page-node-121 .region-content ,
.page-taxonomy-term .region-content ,
.page-eyewears .region-content {
	padding: 0 25px;
}
.not-front .content-header-inner h1 {
	margin-top: 0;
	padding-bottom: 0;
}
	/*-Content header inner-*/
	.not-front .content-header-inner {
		margin-top: 30px;
		margin-bottom: 10px;
		padding: 0 25px;
	}
	/*--*/

	/*-node-*/
	.node-type-distribution .node .field-type-image ,
	.node-type-page .node .field-type-image {
		display: table;
		padding:0;
		clear: both;
		margin: 20px auto 0 auto;
	}
	.node-type-distribution #content-area.has-c-first .node .field-type-image ,
	.node-type-page #content-area.has-c-first .node .field-type-image {
		display: block;
	}
	.node-type-distribution .node .field-name-body ,
	.node-type-page .node .field-name-body {
		margin-top: 10px;
	}
	.node-type-distribution .node .field-name-field-bottom-images ,
	.node-type-page .node .field-name-field-images-ns {
		width: 100%;
		margin-top: 45px;
	}
	.node-type-distribution .node .field-name-field-bottom-images .field-items ,
	.node-type-page .node .field-name-field-images-ns .field-items {
		overflow: hidden;
		display: table;
		margin:0 auto;
	}
	.node-type-distribution .node .field-name-field-bottom-images .field-item ,
	.node-type-page .node .field-name-field-images-ns .field-item {
		display: table;
		float: left;
		position: relative;
	}
	.node-type-distribution .node .field-name-field-bottom-images .field-item:after ,
	.node-type-page .node .field-name-field-images-ns .field-item:after {
		content: " ";
		position: absolute;
		left: 0;
		top: 0;
		width: 275px;
		height: 100px;
		background-color: rgba(34 ,34 ,34 ,0.5);
	}
	.node-type-distribution .node .field-name-field-bottom-images .field-item:hover:after ,
	.node-type-page .node .field-name-field-images-ns .field-item:hover:after {
		background-color: rgba(0 ,0 ,0 ,0);
		display: none;
	}
	.node-type-distribution .node .field-name-field-bottom-images .field-item:first-child ,
	.node-type-page .node .field-name-field-images-ns .field-item:first-child {
		margin-left: 0;
	}
	/*--*/

	/*- Contact us page-node-121 -*/
	.page-node-121 .region-content-first {
		margin: 0;
		padding:0;
	}
	.page-node-121 .captcha .fieldset-description {
		display: none;
	}
	.page-node-121 .captcha img {
		display: block;
	}
	.page-node-121 .captcha input[type="text"] {
		width: 30%;
	}
	/*--*/

/*===================================================*/

/* Content first
-----------------------------------------------------*/
.view-taxonomy-image-brand-logo-for-category- {
	margin-bottom: 20px;
}
.view-taxonomy-image-brand-logo-for-category- .views-row img {
	width: 100%;
	height: auto;
}
.view-taxonomy-image-brand-logo-for-category- .views-row {
	display: block;
	width: 144px;
	margin: 0 auto;
}
#content-first {
	background-color: #f3f3f3;
	border-right: 1px solid #ccc;
	min-height: 500px;
}
.region-content-first {
	padding: 0 16px;
	margin-top: 35px;
	margin-bottom: 35px;
}
#content-first img {
	max-width: 100%;
	height: auto;
	display: block;
	margin-bottom: 0 auto;
}
#block-views-full_node_detail-block {
	margin-bottom: 10px;
}
#block-views-full_node_detail-block .content * {
	font-weight: 400;
	font-size: 13px;
	font-family: 'Raleway';
	text-transform: capitalize;
}
#block-views-full_node_detail-block .content {
	margin-top:15px;
}
#block-views-full_node_detail-block h2.block-title {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
}
#block-views-full_node_detail-block .views-field {
	overflow: hidden;
	width: 100%;
	border-bottom: 1px dashed #ccc;
	padding-bottom:5px;
	margin-bottom:15px;
}
#block-views-full_node_detail-block .views-field:last-child {
	border:0px;
}
#block-views-full_node_detail-block .views-field .views-label {
	float: left;
}
#block-views-full_node_detail-block .views-field .field-content {
	float: right;
}
/*===================================================*/

/* Content & Content first
-----------------------------------------------------*/
#content_inner_place {
	overflow: hidden;
	width: 100%;
}
.not-front #content-area.has-c-first {
	width: 825px;
	float: right;
}
.not-front #content-first {
	float: left;
	width: 275px;
}
/*===================================================*/

/* Content second
-----------------------------------------------------*/
.region-content-second {
	padding: 0 25px;
	overflow: hidden;
}
.region-content-second .block {
	clear: both;
	overflow: hidden;
}
	/*-#block-block-2-*/
	#block-block-2 {
		clear: both;
		width: 100%;
		margin-bottom: 25px;
	}
	#block-block-2 .content {
		overflow: hidden;
		width: 100%;
		padding: 40px 0;
	}
	.logo-images .inner {
		display: table-cell;
		width: 100%;
		height: 120px;
		vertical-align: middle;
	}
	.logo-images img {
		display: block;
		width: auto;
		height: auto;
		max-width: 100%;
		margin: 0 auto;
	}
	.logo-images {
		table-layout:fixed;
		display: table;
		width: 25%;
		float: left;
	}
	.logo-images:nth-child(4n+1) {
		clear: left;
	}
	/*--*/
	
	/*-#block-views-featured_products-block-*/
	#block-views-featured_products-block .block-inner {
		position: relative;
	}
	#block-views-featured_products-block .inner {
		overflow: hidden;
		width: 100%;
	}
	#block-views-featured_products-block .content {
		padding-top: 20px;
	}
	#block-views-featured_products-block .view-header .border {
		width: 100%;
		height: 1px;
		background-color: #777;
		position: absolute;
		top: 10px;
		left: 0;
		z-index: 9;
	}
	#block-views-featured_products-block h2.block-title {
		padding-right: 30px;
		background-color: #fff;
		display: table;
	}
	.views-slideshow-controls-text {
		display: table;
		position: absolute;
		right: 0;
		top: 2px;
		padding-left: 30px;
		z-index: 10;
		background-color: #fff;
	}
	.views-slideshow-controls-text span {
		display: inline-block;
	}
	.views-slideshow-controls-text span a {
		display: block;
		width: 11px;
		height: 17px;
		margin: 0 4px;
		font-size: 0;
		color: rgba(0 ,0 ,0 ,0);
		background-repeat: no-repeat;
		background-position: center center;
		overflow: hidden;
	}
	.views-slideshow-controls-text-previous a {
		background-image: url('../images/left.png');
	}
	.views-slideshow-controls-text-next a {
		background-image: url('../images/right.png');
	}
	.views-slideshow-controls-text-pause {
		display: none !important;
	}
	#block-views-featured_products-block .views_slideshow_cycle_teaser_section {
		width: 100% !important;
		min-height: 222px !important;
	}
	#block-views-featured_products-block .views_slideshow_cycle_slide {
		width: 100% !important;
		height: auto !important;
	}
	#block-views-featured_products-block .views-row {
		display: table;
		width: 100%;
		height: auto;
		overflow: hidden;
		height: auto;
		padding: 5px 5px 5px 0;
	}
	#block-views-featured_products-block .views-field-body {
		width: 56%;
		overflow: hidden;
		float: left;
		margin-right: 1%;
	}
	#block-views-featured_products-block .views-field-body * {
		font-family: Raleway ,zawgyi-one;
		font-size: 13px;
		font-weight: 400;
		color: #000;
	}
	#block-views-featured_products-block .views-field-field-product-preview {
		width: 43%;
		float: right;
		box-shadow: 0 0 5px #888;
		-moz-box-shadow: 0 0 5px #888;
		-webkit-box-shadow: 0 0 5px #888;
		-o-box-shadow: 0 0 5px #888;
		overflow: hidden;
	}
	#block-views-featured_products-block .views-field-field-product-preview .field-content a {
	    display: table;
	    width: 100%;
	}
	#block-views-featured_products-block .views-field-field-product-preview .field-content img {
	    display: block;
	    margin: 0 auto;
	}
	#block-views-featured_products-block {
		width: 100%;
		float: none;
		clear: both;
	}
	/*--*/

	/*-#block-bean-promotion-image-*/
	#block-bean-promotion-image {
		clear: both;
		display: block;
		float: none;
		width: 100%;
	}
	#block-bean-promotion-image img {
	    max-width: 1050px;
	    height: auto;
	}
	#block-bean-promotion-image h2.block-title {
		display: table;
		background-color: #fff;
		padding-right: 30px;
	}
	#block-bean-promotion-image .block-inner:after {
		content: ' ';
		display: block;
		width: 100%;
		height: 1px;
		background-color: #777;
		position: absolute;
		left: 0;
		top: 10px;
		z-index: 0;
	}
	#block-bean-promotion-image .block-inner {
		position: relative;
	}
	#block-bean-promotion-image .content {
		position: relative;
	}
	#block-bean-promotion-image .field-item {
		margin-top: 30px;
	}
	/*--*/

	/*-#block-views-new_products-block-*/
	#block-views-new_products-block {
		padding-top: 60px;
		margin-bottom: 30px;
	}
	#block-views-new_products-block h2.block-title {
		display: table;
		background-color: #fff;
		padding-right: 30px;
	}
	#block-views-new_products-block .block-inner {
		position: relative;
		overflow: hidden;
	}
	#block-views-new_products-block .block-inner:after {
		content: ' ';
		display: block;
		width: 100%;
		height: 1px;
		background-color: #777;
		position: absolute;
		left: 0;
		top: 10px;
		z-index: 0;
	}
	#block-views-new_products-block .view-content {
		overflow: hidden;
		width: 100%;
	}
	#block-views-new_products-block .views-row {
		padding-top: 50px;
		float: left;
		width: 262px;
		overflow: hidden;
		position: relative;
	}
	#block-views-new_products-block .views-row:after {
		position: absolute;
		top: 50px;
		right: 0px;
		content:' ';
		width: 1px;
		height: 87px;
		background-color: #aaa;
	}
	#block-views-new_products-block .views-row:nth-child(8):after ,
	#block-views-new_products-block .views-row:nth-child(4):after {
		position: static;
		width: 0;
	}
	#block-views-new_products-block .views-row:nth-child(n+5) {
		margin-top: 50px;
	}
	#block-views-new_products-block .views-row:nth-child(n+5) .views-field-field-product-preview2:after {
		position: absolute;
		top: 0;
		right: 90px;
		content:' ';
		height: 1px;
		width: 87px;
		background-color: #aaa;
	}
	#block-views-new_products-block .views-row:nth-child(4) {
		padding-right: 0;
	}
	#block-views-new_products-block .views-row:nth-child(5) {
		clear: left;
		padding-right: 0;
	}
	#block-views-new_products-block .views-field {
		display: table;
		margin: 0 auto;
	}
	#block-views-new_products-block .views-field-field-product-preview2 {
	}
	#block-views-new_products-block .views-field-nothing {
		margin-top:15px;
	}
	#block-views-new_products-block .views-field-title {
		font-size: 12px;
		margin-top: 5px;
		font-weight: 400;
	}
	/*--*/

/*===================================================*/

/* Comments
-----------------------------------------------------*/
.node .links ul {
	padding:20px 25px 0 25px;
	border-top:1px solid #ccc;
	margin:0;
	margin-top: 20px;
	display: block;
}
.node .links ul li {
	padding: 0;
	display: inline-block;
}
.comment-wrapper {
	padding:20px 25px 0 25px;
	border-top:1px solid #ccc;
	margin:0;
	margin-top: 20px;
}
/*===================================================*/

/* Pre footer
-----------------------------------------------------*/
.region-pre-footer {
	max-width: 1100px;
	width: 100%;
	margin: 20px auto 0 auto;
	overflow: hidden;
}
.region-pre-footer .block {
	width: 25%;
	float: left;
}
.region-pre-footer h2.block-title {
	font-family: Raleway ,zawgyi-one;
	font-weight: 700;
	margin-bottom: 10px;
}
.region-pre-footer ul {
	margin-bottom: 10px;
}
	/*-#block-block-4-*/
	#block-block-4 {
		overflow: hidden;
	}
	.about-us-body {
		overflow: hidden;
		display: block;
	}
	.about-us-body li {
		padding-left: 20px;
		display: block;
		font-size: 13px;
		font-weight: 400;
		color: #000;
	}
	/*--*/

	/*-#block-menu-menu-clone-menu .content-*/
	#block-menu-menu-clone-menu {
		width: 35%;
		margin-left: 40px;
	}
	#block-menu-menu-clone-menu .content ul {
		overflow: hidden;
		width: 100%;
		margin: 0;
	}
	#block-menu-menu-clone-menu .content li {
		float: left;
		display: block;
		clear: right;
		padding: 0;
		margin: 0 0 3px 0;
	}
	#block-menu-menu-clone-menu .content li:nth-child(even) {
		float: right;
	}
	#block-menu-menu-clone-menu .content li .nolink ,
	#block-menu-menu-clone-menu .content li a {
		display: block;
		text-transform: uppercase;
		font-family: Raleway ,zawgyi-one;
		font-size: 13px;
		font-weight: 500;
		cursor: pointer;
	}
	/*--*/

	/*-#block-block-5-*/
	#block-block-5 {
		float: right;
	}
	#block-block-5 li {
		display: block;
		font-size: 13px;
		padding: 0;
		margin: 0;
	}
	/*--*/

/*===================================================*/


/* Footer
-----------------------------------------------------*/
#footer {
	background-color: #f5f5f5;
}
.region-footer {
	padding: 40px 0;
	width: 100%;
	max-width: 1100px;
	margin: 0 auto;
}
.region-footer p {
	margin: 0;
	display: block;
	text-align: right;
}
.region-footer * {
	font-size: 12px;
	font-weight: 400;
}
/*===================================================*/

/* FieldSlider & POPUP images
-----------------------------------------------------*/
.field-slideshow-wrapper {
	position: relative;
	box-shadow: 0 0 0;
	-webkit-box-shadow: 0 0 0;
	-moz-box-shadow: 0 0 0;
	-o-box-shadow: 0 0 0;
}
.field-slideshow-pager {
	display: table;
	bottom: 10px;
	right: 20px;
	width: auto;
	position: absolute;
	z-index: 15;
}
.field-slideshow-pager a {
	border:2px solid #E21F26;
	display: inline-block;
	font-size: 0px;
	color: rgba(0 ,0 ,0 ,0);
	width: 15px;
	height: 15px;
	margin-left: 10px;
	border-radius: 100px;
	box-shadow: 0px 0px 0px;
	-webkit-box-shadow: 0px 0px 0px;
	-moz-box-shadow: 0px 0px 0px;
	-o-box-shadow: 0px 0px 0px;
	background-color: rgba(0 ,0 ,0 ,0);
}
.field-slideshow-pager a.activeSlide {
	background-color: #000;
}
.field-slideshow-wrapper {
	overflow: hidden;
}
.field-slideshow-wrapper:after ,
.field-slideshow-wrapper:before {
	left: 0;
	position: absolute;
	color: #f3f3f3;
	font-size: 50px;
	font-family: 'Raleway';
	text-align: center;
}
.field-slideshow-wrapper:after {
	content: " ";
	top: 0;
	width: 1100px;
	height: 600px;
	background-color: #333;
	opacity: 0.8;
	z-index: 10;
}
.field-slideshow-wrapper:before {
	content: "LOADING";
	top: 50%;
	width: 1100px;
	z-index: 11;
}
.has-c-first .field-slideshow-wrapper:after ,
.has-c-first .field-slideshow-wrapper:before {
	width: 825px;
}
.field-slideshow-wrapper.complete:after ,
.field-slideshow-wrapper.complete:before {
	display: none;
}
#cboxPrevious {
	background-image: url("../images/left.png") !important;
	background-position: center !important;
}
#cboxNext {
	background-image: url("../images/right.png") !important;
	background-position: center !important;
}
#cboxClose {
	background-image: url("../images/cross.png") !important;
	background-position: center !important;
}
#cboxLoadedContent {
	border:0px;
	margin-bottom: 0px;
}
/*===================================================*/


/* page-eyewears
-----------------------------------------------------*/
.page-eyewears #content-header {
	display: none;
}
	/*-content-first-*/
	.views-exposed-widgets {
		position: relative;
		margin: 0;
	}
	.views-exposed-widgets label {
		font-size: 20px;
		text-transform: uppercase;
		font-weight: 300;
	}
	.views-exposed-widgets .form-item label {
		font-size: 12px;
		text-transform: capitalize;
		font-family: Raleway ,zawgyi-one;
		font-weight: 400;
	}
	.views-exposed-widget {
		padding: 0 !important;
		width: 100%;
		margin-bottom: 30px;
		float: none;
		clear: both;
	}
	.views-exposed-widget .views-widget {
		margin-top: 15px;
		margin-left: 15px;
	}
	.views-reset-button ,
	.views-submit-button {
		display: table;
		width: auto;
		float: right !important;
		clear: none;
		margin-bottom: 0;
	}
	.views-reset-button {
		position: relative;
		left: 1px;
	}
	.views-submit-button .ajax-progress {
		float: none;
		bottom: 6px;
		left: 170px;
		position: absolute;
		display: table;
	}
	/*--*/

	/*-content-*/
	.page-eyewears #block-system-main ,
	.page-taxonomy-term #block-system-main ,
	#block-views-brand_images-block {
		margin-top: 20px;
	}
	.view-content {
		overflow: hidden;
	}
	.page-taxonomy-term .view-id-taxonomy_term .views-row ,
	.page-eyewears .view-new-products .views-row {
		display: table;
		float: left;
		position: relative;
		width: 258px;
		padding-top: 30px;
		margin-top: 30px;
	}
	.page-taxonomy-term .view-id-taxonomy_term .views-row:nth-child(n+4) .views-field-field-product-preview2:after ,
	.page-eyewears .view-new-products .views-row:nth-child(n+4) .views-field-field-product-preview2:after {
		position: absolute;
		top: 0;
		right: 85px;
		content:' ';
		height: 1px;
		width: 87px;
		background-color: #aaa;
	}
	.page-taxonomy-term .view-id-taxonomy_term .views-row:after ,
	.page-eyewears .view-new-products .views-row:after {
		position: absolute;
		top: 50px;
		right: 0px;
		content:' ';
		width: 1px;
		height: 87px;
		background-color: #aaa;
	}
	.page-taxonomy-term .view-id-taxonomy_term .views-row:nth-child(3) ,
	.page-eyewears .view-new-products .views-row:nth-child(3) ,
	.page-taxonomy-term .view-id-taxonomy_term .views-row:nth-child(2) ,
	.page-eyewears .view-new-products .views-row:nth-child(2) ,
	.page-taxonomy-term .view-id-taxonomy_term .views-row:nth-child(1) ,
	.page-eyewears .view-new-products .views-row:nth-child(1) {
		padding-top: 0;
		margin-top: 0;
	}
	.page-taxonomy-term .view-id-taxonomy_term .views-row:nth-child(3):after ,
	.page-eyewears .view-new-products .views-row:nth-child(3):after ,
	.page-taxonomy-term .view-id-taxonomy_term .views-row:nth-child(2):after ,
	.page-eyewears .view-new-products .views-row:nth-child(2):after ,
	.page-taxonomy-term .view-id-taxonomy_term .views-row:nth-child(1):after ,
	.page-eyewears .view-new-products .views-row:nth-child(1):after {
		top: 10px;
	}
	.page-taxonomy-term .view-id-taxonomy_term .views-row-last:after ,
	.page-eyewears .view-new-products .views-row-last:after ,
	.page-taxonomy-term .view-id-taxonomy_term .views-row:nth-child(3n):after ,
	.page-eyewears .view-new-products .views-row:nth-child(3n):after {
		position: static;
	}
	.page-taxonomy-term .view-id-taxonomy_term .views-row:nth-child(3n+1) ,
	.page-eyewears .view-new-products .views-row:nth-child(3n+1) {
		clear: left;
	}
	.page-taxonomy-term .view-id-taxonomy_term .views-field ,
	.page-eyewears .view-new-products .views-field {
		display: table;
		margin: 0 auto;
	}
	/*--*/
/*===================================================*/

/* Pager
------------------------------------------------------*/
.item-list {
	margin: 50px 0 0 0;
}
.item-list .pager {
	display: table;
	overflow: hidden;
	margin: 0 auto;
	position: relative;
}
.item-list .pager .ajax-progress {
	position: absolute;
	float: none;
	bottom: 3px;
}
.item-list .pager li.pager-next .ajax-progress {
	right: 24px;
}
.item-list .pager li.pager-previous .ajax-progress {
	left: 24px;
}
.item-list .pager li {
	display: table;
	float: left;
	margin:0;
	padding: 0;
}
.item-list .pager li.pager-current {
	padding:3px 15px;
}
.item-list .pager li a {
	color: #fff;
	padding:3px 15px;
	font-size: 0;
	height: auto;
	vertical-align: top;
	display: table;
	background-repeat: no-repeat;
	background-position: center;
}
.item-list .pager li.pager-next a {
	background-image: url('../images/right.png');
}
.item-list .pager li.pager-previous a {
	background-image: url('../images/left.png');
}
/*===================================================*/

/* node-type-eyewears
-----------------------------------------------------*/
.node-type-feature-products .node .field-type-image .field-item ,
.node-type-eyewears .node .field-type-image .field-item {
	display: table;
	position: relative;
	padding: 3px;
	margin:50px auto 0 auto;
}
.node-type-feature-products .node .field-type-image .field-item:nth-child(2) ,
.node-type-eyewears .node .field-type-image .field-item:nth-child(2) ,
.node-type-feature-products .node .field-type-image .field-item:first-child ,
.node-type-eyewears .node .field-type-image .field-item:first-child {
	margin-top: 0;
}
.node-type-feature-products .node .field-type-image .field-item.even ,
.node-type-eyewears .node .field-type-image .field-item.even {
	float: left;
}
.node-type-feature-products .node .field-type-image .field-item.odd ,
.node-type-eyewears .node .field-type-image .field-item.odd {
	float: right;
}
.node-type-feature-products .node .field-type-image .field-item.even:after ,
.node-type-eyewears .node .field-type-image .field-item.even:after {
	content: ' ';
	position: absolute;
	width: 1px;
	height: 130px;
	top: 40px;
	right: -33px;
	background-color: #aaa;
}
.node-type-feature-products .node .field-type-image .field-item:before ,
.node-type-eyewears .node .field-type-image .field-item:before {
	content: ' ';
	position: absolute;
	height: 1px;
	width: 130px;
	top: -20px;
	left: 110px;
	background-color: #aaa;
}
.node-type-feature-products .node .field-type-image .field-item:nth-child(2):before ,
.node-type-eyewears .node .field-type-image .field-item:nth-child(2):before ,
.node-type-feature-products .node .field-type-image .field-item:first-child:before ,
.node-type-eyewears .node .field-type-image .field-item:first-child:before ,
.node-type-feature-products .node .field-type-image .field-item.even:last-child:after ,
.node-type-eyewears .node .field-type-image .field-item.even:last-child:after {
	opacity: 0;
}
.node-type-feature-products .field-label ,
.node-type-eyewears .field-label {
	text-transform: capitalize;
}
.node-type-feature-products .field-name-body ,
.node-type-eyewears .field-name-body {
	border-top: 1px solid #ccc;
	padding-top: 10px !important;
	margin-bottom: 20px;
}
.node-type-feature-products .field-label-inline ,
.node-type-eyewears .field-label-inline {
	border-bottom: 1px dashed #ccc;
	padding-left:0 !important;
	padding-right:0 !important;
	padding-bottom:5px !important;
	margin: 0 25px 10px 25px;
}
.node-type-feature-products .field-label-inline .field-items ,
.node-type-eyewears .field-label-inline .field-items {
	float: right;
}
.node-type-feature-products .field-name-field-gender .field-items .field-item:after ,
.node-type-eyewears .field-name-field-gender .field-items .field-item:after {
	content: ' , ';
	margin-right: 5px;
}
.node-type-feature-products .field-name-field-gender .field-items .field-item:last-child:after ,
.node-type-eyewears .field-name-field-gender .field-items .field-item:last-child:after {
	content: '';
	margin-right: 0;
}
.node-type-feature-products .field .field-items .field-item ,
.node-type-eyewears .field .field-items .field-item {
	display: inline-block;
}
.node-type-feature-products .field-type-image ,
.node-type-eyewears .field-type-image {
	overflow: hidden;
	clear: both;
	float: none;
	margin-bottom: 20px;
}
#zoom-img-wrapper {
	box-shadow: 0 0 3px #999;
}
/*===================================================*/

/* Doctor list page
-----------------------------------------------------*/
.page-doctor-list-m .view-content ,
.page-doctor-list .view-content {
	margin-top: 20px;
}
.page-doctor-list-m .doctor-detail-wrapper .field-content p ,
.page-doctor-list .doctor-detail-wrapper .field-content p {
	display: block;
	margin:3px 0;
}
.page-doctor-list-m .doctor-detail-wrapper .views-row ,
.page-doctor-list .doctor-detail-wrapper .views-row {
	text-align: center;
	margin:0;
	padding:0 30px;
	display: table;
	width: 320px;
	float: left;
	z-index: 9;
	position: relative;
}
.page-doctor-list-m .doctor-detail-wrapper .views-row:after ,
.page-doctor-list .doctor-detail-wrapper .views-row:after {
	content: " ";
	border-left:1px solid #ececec;
	position: absolute;
	right: 0;
	top: 0;
	width: 1px;
	z-index: 10;
	height: 1000px;
}
.page-doctor-list-m .doctor-detail-wrapper .views-row:last-child:after ,
.page-doctor-list .doctor-detail-wrapper .views-row:last-child:after {
	opacity: 0;
}
.page-doctor-list-m .doctor-detail-wrapper ,
.page-doctor-list .doctor-detail-wrapper {
	display: table;
	margin:0 auto 25px auto;
	overflow: hidden;
}
.page-doctor-list-m .doctor-detail-wrapper .views-row:last-child ,
.page-doctor-list .doctor-detail-wrapper .views-row:last-child {
	margin-right: 0;
	border-right: 0px;
}
.page-doctor-list-m .doctor-detail-wrapper:last-child ,
.page-doctor-list .doctor-detail-wrapper:last-child {
	margin-bottom: 0;
}


#block-views-doctor_list_grouped-block_1 {
	margin-top: 30px;
	padding: 0 25px;
}
#block-views-doctor_list_grouped-block_1 .group-title {
	position: relative;
	text-align: center;
	margin-bottom: 25px;
	font-size: 1.15em;
	font-weight: 700;
}
#block-views-doctor_list_grouped-block_1 .view-content {
	display: grid;
	grid-template-columns: 100%;
	grid-gap: 50px;
}
#block-views-doctor_list_grouped-block_1 .v-rows {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(4, minmax(0, 1fr));
}
#block-views-doctor_list_grouped-block_1 .title {
	color: #e21f26;
	font-weight: 600;
	font-size: 1.12em;
}
#block-views-doctor_list_grouped-block_1 .about {
	padding-top: 15px;
	border-top: 1px dashed #ccc;
	margin-top: 15px;
}
#block-views-doctor_list_grouped-block_1 .about p {
	margin-bottom: 4px;
}
/*===================================================*/

/* Distribution page
-----------------------------------------------------*/
.node-type-distribution .node .field-name-field-side-images .field-items {
	overflow: hidden;
}
.node-type-distribution .node .field-name-body {
	display: block;
	min-width: 500px;
	float: left;
}
.node-type-distribution .node .field-name-field-bottom-images {
	padding-top: 45px;
	margin-top: 0;
	clear: both;
}
.node-type-distribution .node .field-name-field-side-images {
	display: block;
	float: right;
	width: 330px;
	margin-right: 25px;
}
.node-type-distribution .node .field-name-field-side-images .field-item {
	display: block;
	float: left;
	margin:5px;
	position: relative;
}
.node-type-distribution .node .field-name-field-side-images .field-item:after {
	content: " ";
	position: absolute;
	left: 0;
	top: 0;
	width: 100px;
	height: 100px;
	background-color: rgba(34 ,34 ,34 ,0.5);
}
.node-type-distribution .node .field-name-field-side-images .field-item:hover:after {
	display: none;
}

/* Edit By Paing Gyi*/

#block-block-1 .content {
	height: 533px;
}

#block-block-1 .big-brand .image-cover {
	height: 532px;
	width: 550px;
}

#block-block-1 .big-brand .brand-image img{
	width: 550px;
	height: 532px;
}

#block-block-1 .small-brand .brand-case img {
	width: 100%;
	height: 266px;
}

#block-block-1 .small-brand .brand-case {
	height: 266px;
}
/*===================================================*/


/* Activities node and listing page */
.node-type-activities .content-header-inner {
  margin-bottom: 25px;
}
.node-type-activities .node .content  .field-name-field-date,
.node-type-activities .node .content .field-name-body {
  padding-top: 25px;
}
.view-activities-listing {
  display: table;
  width: 100%;
  margin: 0 auto;
  padding: 0 25px ;
}
.view-activities-listing .views-row {
  clear: both;
  display: table;
  width: 100%;
  max-width: 1050px;
  padding: 25px 0;
  border-bottom: 1px solid #999;
}
.view-activities-listing .views-row .views-field-field-activity-image {
  display: table-cell;
  width: 315px;
  height: auto;
  vertical-align: middle;
}
.view-activities-listing .views-row .text {
  display: table-cell;
  width: 730px;
  vertical-align: middle;
  padding: 15px;
  padding-left: 25px;
}
.view-activities-listing .views-row .text .views-field-title a {
  font-weight: 600;
  font-size: 15px;
}
.view-activities-listing .views-row .text .views-field-field-date {
  margin: 15px 0;
}
.view-activities-listing .views-row .text .views-field-body a {
  display: block;
  width: 120px;
  margin-top: 15px;
  padding: 5px 10px;
  background-color: rgba(0,0,0,0.6);
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}
.view-activities-listing .views-row-last {
	border-bottom: 0;   
}